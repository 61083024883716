<template>
  <ModalTemplate :showModal="showModal" width="650px" @closeModal="closeModal">
    <template v-slot:headerModal>
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <div class="flex-center-vertical">
          <img src="@/assets/icons/profile-picture-used.svg" alt="" />
          <p class="averta-bold label-18 ml-3">Pilih Konsultan Survey</p>
        </div>
        <div class="is-clickable" @click="closeModal">
          <b-icon icon="close"></b-icon>
        </div>
      </div>
    </template>
    <template v-slot:bodyModal>
      <div v-if="isLoading && dataKonsultan.length === 0" class="mt-4">
        <SpinnerIcon />
      </div>
      <div v-else class="body-modal pt-3">
        <b-input
          placeholder="Cari Tukang Jagoan"
          type="search"
          icon="magnify"
          v-model="keyword"
          class="input-search-2 input-none mx-4 mb-3"
        ></b-input>

        <div class="list-konsultan-wrapper">
          <div
            v-for="(item, index) in filteredDataKonsultan"
            :key="index"
            :class="{ active: selected && selected.id === item.id }"
            class="list-konsultan"
          >
            <div class="flex-center">
              <div class="konsultan-img flex-center">
                <img
                  :src="
                    item.image_url
                      ? item.image_url
                      : require('@/assets/icons/profile-picture-used.svg')
                  "
                  alt=""
                />
              </div>
              <div>
                <p class="averta-bold">{{ item.name }}</p>
                <p class="color-grey label-12">{{ item.id }}</p>
                <p class="color-grey label-12">{{ item.vendor && item.vendor.name }}</p>
              </div>
            </div>
            <div
              class="flex-1 mr-3 is-flex is-justify-content-flex-end is-clickable"
              @click="() => handleSelect(item)"
            >
              <ButtonApp :isSecondary="true">
                <p class="averta-bold px-2 py-1">
                  {{ selected && selected.id === item.id ? 'Batalkan' : 'Pilih' }}
                </p>
              </ButtonApp>
            </div>
          </div>
        </div>
        <div class="footer-list-konsultan">
          <ButtonApp @click="handleSubmit" :isDisabled="!selected">
            <p class="averta-bold px-3 py-2">Lanjutkan</p>
          </ButtonApp>
        </div>
      </div>
    </template>
  </ModalTemplate>
</template>

<script>
import { mapGetters } from 'vuex'
// eslint-disable-next-line no-unused-vars
import { alertErrorMessage } from '@/helpers'
import { consultants } from '../example-data.js'
export default {
  name: 'ModalSelectConsultant',
  components: {
    ModalTemplate: () => import('@/components/modal/ModalTemplate'),
    SpinnerIcon: () => import('@/components/icons/SpinnerIcon'),
    ButtonApp: () => import('@/components/button/ButtonApp')
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataKonsultan: [],
      keyword: '',
      isLoading: false,
      showModal: false,
      selected: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    filteredDataKonsultan () {
      return this.dataKonsultan.filter((item) => {
        return item.name.toLowerCase().includes(this.keyword.toLowerCase())
      })
    }
  },
  methods: {
    closeModal () {
      this.showModal = false
      this.$emit('closeModal')
    },
    getKonsultanSurvey () {
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
        this.dataKonsultan = consultants.filter((item) => item.name)
      }, 500)
      // this.$store
      //   .dispatch('projectV2/getKonsultanSurvey')
      //   .then((res) => {
      //     this.isLoading = false
      //     this.dataKonsultan = res.data.data
      //   })
      //   .catch((err) => {
      //     alertErrorMessage(err)
      //   })
    },
    handleSelect (item) {
      if (this.selected && this.selected.id === item.id) {
        this.selected = null
      } else {
        this.selected = item
      }
    },
    handleSubmit () {
      this.$emit('selected', this.selected)
    }
  },
  mounted () {
    this.getKonsultanSurvey()
  },
  watch: {
    open (val) {
      this.showModal = val
      if (val && this.dataKonsultan.length < 1) {
        this.getKonsultanSurvey()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.list-konsultan-wrapper {
  height: 28em;
  overflow: auto;
}

.list-konsultan {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 20px;

  &:hover {
    background: #e7f8ec;
  }
  &.active {
    background: #e7f8ec;
  }
}

.konsultan-img {
  overflow: hidden;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.footer-list-konsultan {
  box-shadow: 0px -3px 30px 0px #00000012;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>
